import React, {useState, useEffect} from "react";

export default function BerufsmantelPrimoBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M388.451,259.451c-2.111-8.451-4.894-28.659-5.595-36.394c-0.568-6.262-7.216-41.442-10.421-58.384
       c-0.715-3.808-1.233-6.529-1.409-7.525c-1.024-5.658-15.771-61.342-20.111-71.469c-4.138-9.655-10.084-17.186-11.626-18.4
       c-0.007-0.013-0.021-0.027-0.028-0.048c-0.05-0.084-0.12-0.21-0.253-0.309c-0.785-0.588-3.744-2.805-4.536-3.387
       c-4.965-3.618-9.901-6.584-15.111-9.073c-5.322-2.538-10.905-4.601-16.591-6.129c-2.573-0.695-4.222-1.178-6.003-1.711
       c-1.24-0.365-2.559-0.757-4.334-1.256c-1.325-3.008-2.903-5.826-4.13-7.952c-2.012-3.477-4.249-6.738-6.64-9.698
       c-0.834-1.023-1.957-2.229-3.289-2.433l-0.59-0.085c-0.665-0.097-1.339-0.196-2.005-0.287l-2.153-0.288
       c-7.271-0.981-14.802-1.998-22.243-1.914c-8.324,0.084-17.095,1.255-24.164,2.201c-0.61,0.085-1.227,0.175-1.837,0.26l-0.806,0.119
       c-1.283,0.196-2.406,1.402-3.24,2.426c-2.39,2.953-4.621,6.221-6.64,9.698c-1.312,2.273-2.988,5.281-4.348,8.458
       c-5.756,1.36-12.537,3.05-19.284,5.294c-3.597,1.199-7.314,2.433-10.77,4.158c-7.882,3.94-16.522,10.952-16.522,12.054
       c-0.048,0.238-0.989,1.788-1.823,3.176c-2.335,3.864-6.241,10.336-9.06,16.914c-4.018,9.375-21.863,64.997-22.852,70.298
       c-1.017,5.399-9.039,57.613-9.741,65.291c-0.693,7.671-3.52,30.903-5.596,39.205l-0.049,0.189
       c-0.048,0.211-0.097,0.428-0.147,0.645c-0.056,0.239-0.105,0.484-0.161,0.722c-0.056,0.266-0.106,0.534-0.162,0.799
       c-0.056,0.288-0.111,0.575-0.168,0.863c-0.056,0.309-0.112,0.624-0.168,0.933l-0.182,0.989c-0.056,0.351-0.119,0.694-0.175,1.045
       c-0.064,0.365-0.127,0.729-0.19,1.094l-1.156,7.425l-3.871,28.709c-0.028,0.245-0.063,0.49-0.098,0.743
       c-0.021,0.098-0.036,0.19-0.036,0.281c-0.007,0.14,0.021,0.266,0.077,0.372c0.085,0.168,0.224,0.238,0.323,0.28v1.01l0.302-0.912
       l0.547,0.183c0.33,0.111,0.66,0.217,0.989,0.323c0.771,0.252,1.543,0.483,2.32,0.715c0.764,0.225,1.536,0.442,2.307,0.645
       c0.765,0.204,1.529,0.4,2.294,0.582c0.757,0.19,1.515,0.365,2.272,0.526c0.75,0.169,1.493,0.323,2.243,0.47
       c0.744,0.148,1.48,0.288,2.216,0.414c0.729,0.134,1.452,0.253,2.182,0.365c0.715,0.112,1.423,0.217,2.138,0.315
       c0.695,0.091,1.389,0.182,2.09,0.266c0.672,0.084,1.353,0.154,2.033,0.224c0.66,0.071,1.318,0.127,1.978,0.183
       c0.637,0.056,1.268,0.104,1.907,0.147c0.617,0.042,1.227,0.076,1.844,0.112c0.59,0.028,1.179,0.056,1.768,0.076
       c0.561,0.015,1.121,0.036,1.682,0.043l1.599,0.02h0.288c0.407,0,0.813-0.007,1.213-0.013c0.47,0,0.94-0.014,1.409-0.028
       c0.434-0.015,0.87-0.028,1.304-0.049c0.4-0.021,0.8-0.042,1.199-0.07c0.358-0.022,0.722-0.049,1.08-0.078
       c0.323-0.027,0.638-0.063,0.961-0.097c0.281-0.028,0.561-0.063,0.842-0.105c0.238-0.036,0.476-0.07,0.708-0.112
       c0.197-0.036,0.393-0.077,0.617-0.133l0.091-0.042c0.029-0.014,0.653-0.386,0.849-1.088c0.273-2.026,6.606-49.612,8.442-54.759
       c1.48-4.13,7.587-37.796,9.594-48.861l0.329-1.803c0.232-1.268,0.701-3.205,1.318-5.63c-0.336,10.84-0.673,21.086-0.75,23.491
       l-0.021,0.512l-18.519,227.106c-0.015,0.097-1.158,9.474-1.052,14.403c0.069,3.219,4.404,5.785,8.022,7.377
       c2.482,1.094,5.132,1.795,7.881,2.097c2.847,0.316,5.694,0.638,8.534,0.954c15.897,1.781,32.334,3.618,48.61,4.432
       c6.528,0.302,13.238,0.456,19.943,0.456c17.916,0,36.512-1.08,55.277-3.219c4.207-0.485,8.505-1.003,12.769-1.55
       c1.235-0.155,2.49-0.287,3.737-0.421c2.693-0.28,5.484-0.568,8.219-1.171c0.301-0.07,0.638-0.141,0.996-0.217l0.112-0.021
       c4.972-1.045,14.213-2.994,14.339-8.716c0.077-3.534-0.483-9.291-0.82-12.405c-0.154-1.388-0.245-2.832-0.287-4.284
       l-15.364-227.568v-27.37c0.743,3.822,1.339,7.174,1.761,9.523l0.309,1.725c1.43,7.86,10.988,47.248,12.474,51.393
       c1.858,5.189,10.168,54.078,10.252,54.569c0.112,0.652,0.716,1.024,0.975,1.121c1.339,0.323,4.362,0.667,8.829,0.667
       c6.549,0,19.704-0.743,34.163-5.744l0.091-0.048c0.084-0.064,0.358-0.316,0.302-0.877
       C394.236,306.02,390.548,267.838,388.451,259.451z"
        />
        <path
          fill="#010101"
          d="M388.276,259.451c-2.111-8.45-4.894-28.659-5.595-36.394c-0.568-6.262-7.216-41.442-10.421-58.384
       c-0.715-3.808-1.233-6.529-1.409-7.525c-1.024-5.658-15.771-61.342-20.111-71.469c-4.138-9.655-10.084-17.186-11.626-18.4
       c-0.007-0.013-0.021-0.027-0.028-0.048c-0.05-0.084-0.12-0.21-0.253-0.309c-0.785-0.588-3.744-2.805-4.536-3.387
       c-4.965-3.618-9.901-6.584-15.111-9.073c-5.322-2.538-10.905-4.601-16.591-6.129c-2.573-0.695-4.222-1.178-6.003-1.711
       c-1.24-0.365-2.559-0.757-4.334-1.256c-1.325-3.008-2.903-5.826-4.13-7.952c-2.012-3.477-4.249-6.738-6.64-9.698
       c-0.834-1.023-1.957-2.229-3.289-2.433l-0.59-0.085c-0.665-0.097-1.339-0.196-2.005-0.287l-2.153-0.288
       c-7.271-0.981-14.802-1.998-22.243-1.914c-8.324,0.084-17.095,1.255-24.164,2.201c-0.61,0.085-1.227,0.175-1.837,0.26l-0.806,0.119
       c-1.283,0.196-2.406,1.402-3.24,2.426c-2.39,2.953-4.621,6.221-6.64,9.698c-1.312,2.273-2.988,5.281-4.348,8.457
       c-5.756,1.36-12.537,3.05-19.284,5.294c-3.597,1.199-7.314,2.433-10.77,4.158c-7.882,3.94-16.522,10.952-16.522,12.054
       c-0.048,0.238-0.989,1.788-1.823,3.176c-2.335,3.864-6.241,10.336-9.06,16.914c-4.018,9.375-21.863,64.997-22.852,70.298
       c-1.017,5.399-9.039,57.613-9.741,65.291c-0.694,7.671-3.52,30.903-5.596,39.205l-0.049,0.189
       c-0.048,0.211-0.097,0.428-0.147,0.645c-0.056,0.239-0.105,0.485-0.161,0.722c-0.056,0.266-0.106,0.534-0.162,0.799
       c-0.056,0.288-0.111,0.575-0.168,0.863c-0.056,0.309-0.112,0.624-0.168,0.933l-0.182,0.989c-0.056,0.351-0.119,0.694-0.175,1.045
       c-0.064,0.365-0.127,0.729-0.19,1.094l-1.156,7.425l-3.871,28.709c-0.028,0.245-0.063,0.49-0.098,0.743
       c-0.021,0.098-0.036,0.19-0.036,0.281c-0.007,0.14,0.021,0.266,0.077,0.372c0.085,0.168,0.224,0.238,0.323,0.28v1.01l0.302-0.912
       l0.547,0.183c0.33,0.111,0.66,0.217,0.989,0.323c0.771,0.252,1.543,0.483,2.32,0.715c0.764,0.225,1.536,0.442,2.307,0.645
       c0.765,0.204,1.529,0.4,2.294,0.582c0.757,0.19,1.515,0.365,2.272,0.526c0.75,0.169,1.493,0.323,2.243,0.47
       c0.744,0.148,1.48,0.288,2.216,0.414c0.729,0.134,1.452,0.253,2.181,0.365c0.715,0.112,1.424,0.217,2.138,0.316
       c0.695,0.091,1.389,0.182,2.09,0.266c0.672,0.084,1.353,0.154,2.033,0.224c0.66,0.071,1.318,0.127,1.978,0.183
       c0.637,0.056,1.269,0.104,1.907,0.147c0.617,0.042,1.227,0.076,1.844,0.112c0.59,0.028,1.179,0.056,1.768,0.076
       c0.561,0.015,1.121,0.036,1.682,0.043l1.599,0.02h0.288c0.407,0,0.813-0.007,1.213-0.013c0.47,0,0.94-0.014,1.409-0.028
       c0.434-0.015,0.87-0.028,1.304-0.049c0.4-0.021,0.8-0.042,1.199-0.07c0.358-0.022,0.722-0.049,1.08-0.078
       c0.323-0.027,0.638-0.063,0.961-0.097c0.281-0.028,0.561-0.063,0.842-0.105c0.238-0.036,0.476-0.07,0.708-0.112
       c0.197-0.036,0.393-0.077,0.617-0.133l0.091-0.042c0.029-0.014,0.653-0.386,0.849-1.088c0.273-2.026,6.606-49.612,8.442-54.759
       c1.48-4.13,7.587-37.796,9.593-48.861l0.329-1.803c0.232-1.269,0.701-3.205,1.318-5.63c-0.336,10.84-0.673,21.086-0.75,23.491
       l-0.021,0.512l-18.519,227.106c-0.015,0.097-1.158,9.474-1.052,14.403c0.069,3.219,4.404,5.785,8.022,7.377
       c2.482,1.094,5.132,1.795,7.881,2.097c2.847,0.316,5.694,0.638,8.534,0.954c15.897,1.781,32.334,3.618,48.61,4.432
       c6.528,0.302,13.238,0.456,19.943,0.456c17.916,0,36.512-1.08,55.277-3.219c4.207-0.485,8.505-1.003,12.769-1.55
       c1.235-0.155,2.49-0.287,3.738-0.421c2.693-0.28,5.484-0.568,8.219-1.171c0.301-0.07,0.638-0.141,0.996-0.217l0.112-0.021
       c4.972-1.045,14.213-2.994,14.339-8.716c0.077-3.534-0.483-9.291-0.82-12.405c-0.154-1.388-0.245-2.832-0.287-4.284
       l-15.364-227.568v-27.37c0.743,3.822,1.339,7.174,1.761,9.523l0.309,1.725c1.43,7.86,10.988,47.248,12.474,51.393
       c1.858,5.189,10.168,54.078,10.252,54.569c0.112,0.652,0.716,1.024,0.975,1.121c1.339,0.323,4.362,0.667,8.829,0.667
       c6.549,0,19.704-0.743,34.163-5.744l0.091-0.048c0.084-0.064,0.358-0.316,0.302-0.877
       C394.061,306.02,390.373,267.838,388.276,259.451 M380.844,213.921c-0.119-0.687-0.238-1.395-0.365-2.132
       C380.612,212.525,380.731,213.234,380.844,213.921 M345.839,77.126c-1.578-2.623-3.114-4.845-4.369-6.486
       C342.732,72.288,344.274,74.496,345.839,77.126 M339.163,67.94c-0.056-0.056-0.112-0.105-0.168-0.154l-0.168-0.155
       C338.924,67.708,339.036,67.806,339.163,67.94 M338.727,67.526c0.015,0.021,0.036,0.041,0.049,0.063l-0.056-0.049L338.727,67.526z
        M291.886,45.654l0.008,0.007l-0.056,0.022c-0.106-0.07-0.218-0.133-0.337-0.197L291.886,45.654z M252.084,23.159
       c3.885,0,7.791,0.287,11.668,0.709c-4.165-0.435-8.372-0.729-12.538-0.701C251.502,23.167,251.79,23.159,252.084,23.159
        M215.312,37.878c1.991-3.449,4.2-6.668,6.557-9.585c0.729-0.898,1.697-1.949,2.707-2.104l0.624-0.091
       c0.659-0.098,1.311-0.19,1.97-0.281c7.033-0.94,15.778-2.111,24.045-2.196c0.259,0,0.513-0.006,0.772-0.006
       c7.208,0,14.501,0.989,21.563,1.942l1.928,0.26c0.673,0.09,1.332,0.189,1.998,0.281l0.632,0.097c0.981,0.148,1.942,1.2,2.671,2.097
       c2.363,2.923,4.573,6.142,6.557,9.585c1.079,1.859,2.447,4.299,3.674,6.936c-0.343-0.105-0.701-0.189-1.052-0.267
       c-0.189-0.041-0.364-0.076-0.526-0.119c-1.599-0.414-3.211-0.757-4.733-1.072c-3.12-0.659-7.236-1.48-11.436-2.055
       c-11.711-1.613-23.666-1.845-35.531-0.687c-5.12,0.498-10.561,1.388-17.124,2.805c-2.398,0.519-4.418,0.961-6.459,1.438
       c-0.302,0.07-0.624,0.133-0.996,0.203c-0.652,0.119-1.247,0.245-1.809,0.414C212.626,42.605,214.128,39.919,215.312,37.878
        M210.894,46.111c-0.091,0.042-0.182,0.084-0.273,0.126l-0.099,0.022L210.894,46.111z M164.052,67.428l0.014,0.063
       c0.056,0.189,0.105,0.379,0.155,0.568l-0.155-0.548l-0.21,0.393C163.976,67.673,164.039,67.519,164.052,67.428 M112.421,277.031
       l0.968-6.269l-1.297,8.723L112.421,277.031z M152.517,311.951c-0.055,0.015-0.112,0.022-0.168,0.036
       c0.07-0.014,0.14-0.028,0.203-0.049L152.517,311.951z M158.366,274.465c0.168-1.101,0.337-2.188,0.505-3.24
       C158.709,272.277,158.541,273.364,158.366,274.465 M159.537,267.053c0.104-0.666,0.217-1.318,0.322-1.949
       C159.761,265.727,159.649,266.379,159.537,267.053 M172.917,198.066c-0.849,3.338-1.522,5.974-1.817,7.594l-0.33,1.801
       c-1.998,11.044-8.098,44.662-9.551,48.715c-1.935,5.399-8.415,54.421-8.477,54.878c-0.05,0.182-0.217,0.351-0.372,0.463
       c-0.147,0.035-0.301,0.063-0.456,0.091c-0.224,0.041-0.449,0.077-0.672,0.104c-0.274,0.043-0.541,0.078-0.813,0.106
       c-0.316,0.034-0.632,0.062-0.94,0.091c-0.358,0.028-0.716,0.056-1.067,0.084c-0.392,0.021-0.785,0.049-1.177,0.062
       c-0.428,0.022-0.855,0.036-1.291,0.05c-0.462,0.013-0.925,0.028-1.388,0.028c-0.498,0.007-0.996,0.014-1.494,0.007
       c-0.526,0-1.052-0.007-1.584-0.015c-0.554-0.013-1.108-0.027-1.67-0.048c-0.581-0.015-1.163-0.042-1.745-0.071
       c-0.611-0.034-1.221-0.069-1.831-0.112c-0.631-0.043-1.262-0.091-1.893-0.14c-0.659-0.056-1.312-0.119-1.964-0.182
       c-0.673-0.071-1.346-0.148-2.02-0.224c-0.694-0.084-1.381-0.169-2.076-0.266c-0.701-0.098-1.409-0.203-2.117-0.309
       c-0.722-0.112-1.438-0.238-2.16-0.364c-0.737-0.126-1.466-0.266-2.203-0.407c-0.743-0.147-1.479-0.302-2.223-0.47
       c-0.75-0.162-1.508-0.337-2.251-0.519c-0.764-0.182-1.522-0.379-2.279-0.582c-0.764-0.203-1.522-0.414-2.286-0.639
       c-0.764-0.224-1.529-0.463-2.293-0.701c-0.33-0.104-0.652-0.217-0.981-0.329l-0.604-0.197v-0.013
       c0.043-0.266,0.078-0.526,0.112-0.792l2.889-21.871l1.943-13.058c0.063-0.379,0.126-0.757,0.189-1.136
       c0.056-0.364,0.119-0.729,0.182-1.093c0.056-0.351,0.119-0.694,0.182-1.045l0.175-0.982l0.168-0.926
       c0.056-0.288,0.112-0.569,0.169-0.856c0.049-0.259,0.105-0.526,0.161-0.785c0.048-0.238,0.098-0.477,0.154-0.716
       c0.041-0.21,0.091-0.42,0.154-0.658l0.035-0.154c2.09-8.352,4.923-31.653,5.625-39.339c0.694-7.672,8.716-59.814,9.718-65.208
       c0.989-5.287,18.794-60.753,22.805-70.101c2.797-6.536,6.683-12.98,9.01-16.823c0.597-0.989,1.052-1.746,1.361-2.292
       c4.017,14.775,5.189,30.117,6.317,44.962c0.667,8.773,1.353,17.84,2.616,26.78c1.247,8.849,1.247,20.307,1.247,29.536l-0.007,1.339
       c0,2.65-0.357,14.873-0.651,24.619L172.917,198.066z M173.379,198.094l0.519-2.076c0.007-0.049,0.007-0.098,0.007-0.147
       l0.043,0.007l-0.05,0.189c-0.021,0.688-0.041,1.368-0.062,2.055l0.041-1.984L173.379,198.094z M324.03,168.51v19.592l-0.28,0.056
       l0.28,1.325v31.983l15.363,227.589c0.043,1.465,0.14,2.924,0.295,4.341c0.337,3.092,0.891,8.8,0.813,12.292
       c-0.111,4.993-8.891,6.844-13.61,7.84l-0.105,0.021c-0.372,0.077-0.708,0.147-1.017,0.217c-2.686,0.589-5.449,0.877-8.142,1.156
       c-1.22,0.134-2.489,0.26-3.738,0.421c-4.242,0.547-8.534,1.066-12.755,1.543c-25.84,2.944-51.098,3.877-75.067,2.762
       c-16.247-0.813-32.67-2.65-48.552-4.424c-2.847-0.323-5.695-0.638-8.542-0.954c-2.65-0.288-5.217-0.975-7.608-2.027
       c-3.38-1.487-7.419-3.829-7.482-6.557c-0.105-4.873,1.032-14.193,1.046-14.291l18.526-227.147l0.013-0.414
       c0.084-2.447,0.506-15.308,0.877-27.706l0.148-0.582l-0.119-0.027c0.427-13.954,0.645-22.096,0.645-24.185v-1.339
       c0-9.242,0.007-20.742-1.256-29.662c-1.254-8.906-1.942-17.944-2.615-26.731c-1.157-15.245-2.357-31.008-6.62-46.148
       c0.589-0.94,7.763-7.195,15.996-11.31c3.4-1.704,7.103-2.931,10.694-4.13c6.69-2.223,13.471-3.913,19.248-5.273l0.029,0.056
       l0.161-0.077l0.113,0.041l0.056-0.119c0.729-0.351,1.668-0.532,2.517-0.694c0.351-0.069,0.695-0.133,1.017-0.21
       c2.013-0.469,4.032-0.911,6.437-1.43c6.529-1.409,11.943-2.292,17.019-2.79c11.816-1.15,23.702-0.919,35.321,0.68
       c4.172,0.575,8.274,1.395,11.38,2.048c1.501,0.316,3.107,0.659,4.692,1.065c0.175,0.043,0.371,0.092,0.574,0.134
       c0.59,0.133,1.325,0.294,1.781,0.61l0.028,0.049l-0.006,0.02l0.07,0.134l0.168-0.071c1.866,0.526,3.226,0.933,4.51,1.312
       c1.76,0.518,3.414,1.01,6.031,1.711c5.63,1.515,11.156,3.562,16.429,6.073c5.154,2.461,10.049,5.406,14.964,8.99
       c0.772,0.568,3.598,2.686,4.467,3.351c-5.294,18.836-7.741,37.474-10.335,57.206l-0.029,0.238
       c-0.638,4.853-1.297,9.867-1.999,14.818C324.647,148.49,324.03,158.117,324.03,168.51 M324.289,188.515l0.196-0.043V168.51
       l0.007,20.974c0.154,0.743,0.302,1.487,0.442,2.208l-0.449,0.043v-2.293L324.289,188.515z M342.978,270.741
       c0.379,1.928,0.757,3.975,1.142,6.073C343.728,274.716,343.348,272.669,342.978,270.741 M344.33,277.964
       c0.267,1.423,0.533,2.875,0.793,4.334C344.856,280.846,344.59,279.393,344.33,277.964 M393.192,306.546
       c-19.501,6.689-38.027,5.988-42.178,5.007c-0.098-0.042-0.344-0.203-0.379-0.421c-0.344-2.012-8.393-49.415-10.293-54.716
       c-1.48-4.131-11.009-43.407-12.433-51.253l-0.308-1.726c-0.562-3.127-1.494-8.359-2.658-14.003V168.51
       c0-10.357,0.611-19.944,1.823-28.491c0.708-5.021,1.374-10.091,2.02-14.999l0.007-0.071c2.566-19.515,4.986-37.95,10.168-56.574
       c2.196,2.167,7.482,9.592,10.946,17.663c4.32,10.077,19.024,65.628,20.048,71.273c0.175,0.982,0.673,3.632,1.374,7.342l0.043,0.196
       c2.937,15.581,9.837,52.066,10.406,58.293c0.701,7.755,3.492,28.034,5.616,36.534c2.083,8.323,5.757,46.427,5.799,46.814V306.546z
        M393.598,306.812c0.021-0.049,0.043-0.127,0.049-0.239C393.641,306.679,393.627,306.756,393.598,306.812"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.3156"
          strokeMiterlimit="10"
          strokeDasharray="0.9417,0.941"
          d="
       M340.089,458.458c-2.034,3.003-10.605,5.552-13.783,6.219c-0.322,0.072-0.602,0.13-0.857,0.184l-0.262,0.055
       c-2.573,0.569-5.249,0.849-7.839,1.121l-0.086,0.009c-1.145,0.116-2.483,0.257-3.806,0.426c-4.285,0.548-8.568,1.066-12.733,1.539
       c-25.304,2.893-50.589,3.882-76.044,2.616c-10.119-0.502-20.142-1.467-30.191-2.616c-4.164-0.473-8.448-0.992-12.732-1.539
       c-1.324-0.17-2.662-0.31-3.807-0.426l-0.086-0.009c-2.589-0.272-5.265-0.553-7.838-1.121l-0.262-0.055
       c-0.256-0.053-0.535-0.112-0.857-0.184c-3.179-0.668-11.75-3.216-13.783-6.219"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.3156"
          strokeMiterlimit="10"
          strokeDasharray="0.9417,0.941"
          d="
       M154.082,301.634c-11.138,2.097-22.614,1.518-33.615-1.133c-3.554-0.857-7.16-1.965-10.314-3.857"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.3156"
          strokeMiterlimit="10"
          strokeDasharray="0.9417,0.941"
          d="
       M348.571,302.117c6.821,1.3,13.572,1.58,20.472,0.759c4.084-0.485,8.181-1.108,12.203-1.965c3.547-0.755,7.199-1.646,10.452-3.295
       c0.339-0.172,0.674-0.355,1.001-0.55"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.3156"
          strokeMiterlimit="10"
          strokeDasharray="0.9417,0.941"
          d="
       M212.426,44.511c2.143-0.501,6.122-1.521,8.273-1.985c5.651-1.219,11.311-2.238,17.072-2.798
       c11.757-1.144,23.726-0.928,35.428,0.684c3.83,0.527,7.624,1.257,11.408,2.049c1.576,0.33,3.822,0.849,5.382,1.251"
        />
      </g>
    </svg>
  );
}
